import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery, saveToStorage, saveToken } from '../utils';

export interface User {
	id: number;
	login: string;
	role: string;
	museum?: any;
}

export interface UserResponse {
	user: User;
	refreshToken: string;
	plan: any;
}

export interface LoginRequest {
	login: string;
	password: string;
}

export interface RegisterRequest {
	login: string;
	password: string;
	museumType: number;
	museumName: string;
	authorPhone: string;
}

export const api = createApi({
	baseQuery: customBaseQuery,
	endpoints: builder => ({
		login: builder.mutation<UserResponse, LoginRequest>({
			query: credentials => ({
				url: '/auth/login',
				method: 'POST',
				body: credentials,
				responseHandler(response) {
					if (response.ok) {
						return response.json().then(data => {
							saveToken(data.refreshToken);
							saveToStorage(data.user);
							return data;
						});
					} else if (response.status === 401) {
						return response.json().then(data => {
							// @ts-ignore
							// eslint-disable-next-line no-throw-literal
							throw {
								...data,
							};
						});
					} else if (response.status === 404) {
						return response.json().then(data => {
							// @ts-ignore
							// eslint-disable-next-line no-throw-literal
							throw {
								...data,
							};
						});
					}

					return response.json().then(data => {
						return data;
					});
				},
			}),
		}),
		register: builder.mutation<any, any>({
			query: credentials => ({
				url: '/auth/register',
				method: 'POST',
				body: credentials,
				responseHandler(response) {
					if (response.ok) {
						return response.json().then(data => {
							saveToken(data.refreshToken);
							saveToStorage(data);
							return data;
						});
					}

					return response.json().then(data => {
						return data;
					});
				},
			}),
		}),
		forgotPassword: builder.mutation<any, any>({
			query: credentials => ({
				url: '/auth/forgot-password',
				method: 'POST',
				body: credentials,
			}),
		}),
		resetPassword: builder.mutation<any, any>({
			query: credentials => ({
				url: '/auth/reset-password',
				method: 'POST',
				body: credentials,
			}),
		}),
	}),
});

export const { useLoginMutation, useRegisterMutation, useForgotPasswordMutation, useResetPasswordMutation } = api;
