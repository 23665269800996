import { Modal, ModalClose, Stack, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useBookingMutation, useDeleteMutation, useUpdateStatusMutation } from '../app/services/booking';
import { useGetMuseumMutation } from '../app/services/museum';
import { selectCurrentUser } from '../features/auth/authSlice';
import { CustomTable } from '../features/components/CustomTable';
import Layout from '../features/components/Layout';
import { Alert } from '../utils/Alert';
import { Loading } from '../utils/Loading';

const ModalCreate = ({ isModalOpen, setIsModalOpen, currentBooking }: { isModalOpen: any; setIsModalOpen: (isOpen: any) => void; currentBooking: any }) => {
	const { t } = useTranslation();

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['view']} onClose={() => setIsModalOpen({ ...isModalOpen, view: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: 'auto',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						top: 'calc(-1/4 * var(--IconButton-size))',
						right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('bookingPage.view')}
				</Typography>
				<Stack spacing={2}>
					<Typography>
						<b>
							{t('bookingPage.name')}: {currentBooking?.name}
						</b>
					</Typography>
					<Typography>
						<b>
							{t('bookingPage.phone')}: {currentBooking?.phone}
						</b>
					</Typography>
					<Typography>
						<b>
							{t('bookingPage.ticker_type')}: {currentBooking?.type === 0 ? 'Екскурсія' : 'Подія'}
						</b>
					</Typography>
					<Typography>
						<b>
							{t('bookingPage.sum')}:{' '}
							{currentBooking?.tickers
								?.map((item: any) => {
									const prices = currentBooking?.excursion?.price[item.type];
									return prices * item.count;
								})
								.reduce((a: any, b: any) => {
									return a + b;
								}, 0)}{' '}
							грн
						</b>
					</Typography>
					<Typography>
						<b>
							{t('bookingPage.count_person')}:{' '}
							{currentBooking?.tickers?.reduce((a: any, b: any) => {
								return a + b.count;
							}, 0)}
						</b>
					</Typography>

					<Typography>
						<b>
							{t('bookingPage.pay_method')}:{' '}
							{
								// @ts-ignore
								t('bookingPage.pay_methods', {
									returnObjects: true,
								})[currentBooking?.pay_type || 0]
							}
						</b>
					</Typography>
					<Typography>
						<b>
							{t('bookingPage.guides')}: {currentBooking?.excursion?.guides?.map((item: any) => item.name).join(', ')}
						</b>
					</Typography>
					<Typography>
						<b>
							{t('bookingPage.halls')}: {currentBooking?.excursion?.halls?.map((item: any) => item.name).join(', ')}
						</b>
					</Typography>

					<Typography>
						<b>
							{t('bookingPage.date_schedule')}: {moment(currentBooking?.booking_date).format('DD.MM.YYYY HH:mm')}
						</b>
					</Typography>
					<Typography>
						<b>
							{t('bookingPage.date_created')}: {moment(currentBooking?.created_at).format('DD.MM.YYYY HH:mm')}
						</b>
					</Typography>
				</Stack>
			</Sheet>
		</Modal>
	);
};

const ModalYouSure = ({ isModalOpen, setIsModalOpen, currentBooking, update }: { isModalOpen: any; setIsModalOpen: (isOpen: any) => void; currentBooking: any; update: any }) => {
	const [updateStatus, { data: dataUpdate, isLoading: isLoadingUpdate, isError: isErrorUpdate, isSuccess: isSuccessUpdate }] = useUpdateStatusMutation();
	const { t } = useTranslation();

	useEffect(() => {
		if (isSuccessUpdate && dataUpdate) {
			update();
		}
	}, [isSuccessUpdate, dataUpdate]);

	return (
		<Modal aria-labelledby="modal-title" aria-describedby="modal-desc" open={isModalOpen['delete']} onClose={() => setIsModalOpen({ ...isModalOpen, delete: false })} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					height: 'auto',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						top: 'calc(-1/4 * var(--IconButton-size))',
						right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Typography component="h2" id="modal-title" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('bookingPage.are_you_sure')}
				</Typography>
				<Stack
					spacing={2}
					direction={'row'}
					sx={{
						mt: 2,
					}}>
					<Button
						onClick={() => {
							updateStatus({ id: currentBooking?.id, status: -1 });
							setIsModalOpen({ ...isModalOpen, delete: false });
						}}>
						Так
					</Button>
					<Button
						onClick={() => {
							setIsModalOpen({ ...isModalOpen, delete: false });
						}}>
						Ні
					</Button>
				</Stack>
			</Sheet>
		</Modal>
	);
};

export default function Booking() {
	const [booking, { data, isLoading, isError }] = useBookingMutation();
	const [updateStatus, { data: dataUpdate, isSuccess: isSuccessUpdate }] = useUpdateStatusMutation();
	const [remove, { data: dataDelete, isLoading: isLoadingDelete, isError: isErrorDelete, isSuccess: isSuccessDelete }] = useDeleteMutation();
	const [getMuseum, { data: dataMuseum, isLoading: isLoadingMuseum, isError: isErrorMuseum, isSuccess: isSuccessMuseum }] = useGetMuseumMutation();

	const [isModalOpen, setIsModalOpen] = useState<any>({});
	const [currentBooking, setCurrentBooking] = useState<any>();
	const user_data = useSelector(selectCurrentUser);

	const [open, setOpen] = useState(false);
	const [color, setColor] = useState<string>('success');
	const [textAlert, setTextAlert] = useState('');

	const { t } = useTranslation();

	const navigator = useNavigate();

	useEffect(() => {
		booking();
		getMuseum();

		if ([2].includes(parseInt(user_data?.museum?.type))) {
			navigator('/settings');
		}
	}, []);

	useEffect(() => {
		if ((isSuccessUpdate && dataUpdate) || isSuccessDelete) {
			booking();
		}
	}, [isSuccessUpdate, dataUpdate, isSuccessDelete]);

	const Actions = ({ id }: { id: number }) => {
		const [current_item, setCurrent_item] = useState<any>(null);

		useEffect(() => {
			const foundItem = data?.filter((item: any) => item.id === id)[0];
			foundItem && setCurrent_item(foundItem);
		}, []);

		return (
			<Box sx={{ display: 'flex', gap: 1 }}>
				{current_item?.status !== 1 && (
					<Button
						size="sm"
						variant="plain"
						color="success"
						data-action
						onClick={() => {
							updateStatus({ id: id, status: 1 });
						}}>
						<i className="fa-solid fa-check"></i>
					</Button>
				)}
				<Button
					size="sm"
					variant="soft"
					color="info"
					data-action
					onClick={() => {
						setCurrentBooking(current_item);
						setIsModalOpen({ ...isModalOpen, view: true });
					}}>
					<i className="fa-solid fa-eye"></i>
				</Button>
				{current_item?.status !== -1 && (
					<Button
						size="sm"
						variant="soft"
						color="danger"
						data-action
						onClick={() => {
							// updateStatus({ id: id, status: -1 });

							setCurrentBooking(current_item);
							setIsModalOpen({ ...isModalOpen, delete: true });
						}}>
						<i className="fa-solid fa-xmark"></i>
					</Button>
				)}
			</Box>
		);
	};

	const DeleteActions = ({ id }: { id: number }) => {
		return (
			<Box sx={{ display: 'flex', gap: 1 }}>
				<Button
					size="sm"
					variant="soft"
					color="danger"
					data-action
					onClick={() => {
						remove(id);
					}}>
					<i className="fa-solid fa-trash"></i>
				</Button>
			</Box>
		);
	};

	const columns = [
		{
			id: 'id',
			disablePadding: false,
			label: 'ID',
			numeric: true,
			width: '70px',
		},
		{
			id: 'tour',
			disablePadding: false,
			label: t('bookingPage.n'),
			numeric: false,
			width: '100px',
		},
		{
			id: 'type',
			disablePadding: false,
			label: t('bookingPage.type'),
			numeric: false,
			width: '100px',
		},
		{
			id: 'date',
			disablePadding: false,
			label: t('bookingPage.date'),
			numeric: true,
			width: '100px',
			isDate: true,
		},
		{
			id: 'name',
			disablePadding: false,
			label: t('bookingPage.name'),
			numeric: false,
			width: '100px',
		},
		{
			id: 'phone',
			disablePadding: false,
			label: t('bookingPage.phone'),
			numeric: false,
			width: '140px',
		},
		{
			id: 'status',
			disablePadding: false,
			label: t('bookingPage.status'),
			numeric: false,
			width: '120px',
		},
	];

	const getBookingStatus = (item: any) => {
		if (moment().diff(item?.created_at, 'days') <= 0) {
			if (item?.type === 1) {
				if (item?.event?.price === 0) {
					return t('bookingPage.newStatus') + ' ' + t('bookingPage.free');
				}
			}

			if (item?.pay_type === 'card') {
				return t('bookingPage.newStatus') + ' ' + (item?.status === 1 ? t('bookingPage.payed') : 'Не оплачений ще');
			} else {
				return t('bookingPage.newStatus') + ' ' + t('bookingPage.notPayed');
			}
		} else {
			if (item?.pay_type === 'card') {
				return item?.status === 1 ? t('bookingPage.payed') : 'Не оплачений ще';
			} else {
				return t('bookingPage.notPayed');
			}
		}
	};

	if (data?.error) {
		return (
			<Layout>
				<Box sx={{ width: '100%' }}>Error: {data?.message}</Box>
			</Layout>
		);
	}

	return (
		<Layout>
			<Alert open={open} setOpen={setOpen} text={textAlert} color={color} />
			<Box sx={{ width: '100%' }}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}>
					<h1>{t('settingsPage.settings')}</h1>
					<p>{t('bookingPage.balance', { amount: dataMuseum?.debt || '0' })}</p>
				</Box>
				<ModalCreate isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} currentBooking={currentBooking} />
				<ModalYouSure isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} currentBooking={currentBooking} update={booking} />

				<Tabs aria-label="Basic tabs" defaultValue={1} sx={{ borderRadius: 'lg' }}>
					<TabList
						sx={{
							flexWrap: 'wrap',
							justifyContent: 'center',
						}}>
						<Tab disabled={false}>{t('bookingPage.pending')}</Tab>
						<Tab>{t('bookingPage.success')}</Tab>
						<Tab>{t('bookingPage.completed')}</Tab>
						<Tab>{t('bookingPage.canceled')}</Tab>
					</TabList>
					<TabPanel value={0} sx={{ p: 2 }}>
						{isLoading ? (
							<Loading />
						) : (
							<CustomTable
								title={t('bookingPage.pending')}
								headCells={columns}
								rows={
									data
										?.filter((item: any) => {
											return item.status === 0 ? moment(item.booking_date).isAfter(moment()) : false;
										})
										?.map((item: any) => {
											return {
												id: item.id,
												tour: item?.excursion?.name || item?.event?.name,
												type: t('bookingPage.' + item.type),
												date: item.booking_date,
												name: item.name,
												phone: item.phone,
												status: getBookingStatus(item),
											};
										}) || []
								}
								Actions={Actions}
								deleteSelected={() => {}}
							/>
						)}
					</TabPanel>
					<TabPanel value={1} sx={{ p: 2 }}>
						{isLoading ? (
							<Loading />
						) : (
							<CustomTable
								title={t('bookingPage.success')}
								headCells={columns}
								rows={
									data
										?.filter((item: any) => {
											return item.status === 1 ? !moment(item.booking_date).isBefore(moment()) : false;
										})
										?.map((item: any) => {
											return {
												id: item.id,
												tour: item?.excursion?.name || item?.event?.name,
												type: t('bookingPage.' + item.type),
												date: item.booking_date,
												name: item.name,
												phone: item.phone,
												status: getBookingStatus(item),
											};
										}) || []
								}
								Actions={Actions}
								deleteSelected={() => {}}
							/>
						)}
					</TabPanel>
					<TabPanel value={2} sx={{ p: 2 }}>
						{isLoading ? (
							<Loading />
						) : (
							<CustomTable
								title={t('bookingPage.completed')}
								headCells={columns}
								rows={
									data
										?.filter((item: any) => {
											return moment(item.booking_date).isBefore(moment()) && item.status === 1;
										})
										?.map((item: any) => {
											return {
												id: item.id,
												tour: item?.excursion?.name || item?.event?.name,
												type: t('bookingPage.' + item.type),
												date: item.booking_date,
												name: item.name,
												phone: item.phone,
												status: getBookingStatus(item),
											};
										}) || []
								}
								Actions={DeleteActions}
								deleteSelected={() => {}}
							/>
						)}
					</TabPanel>
					<TabPanel value={3} sx={{ p: 2 }}>
						{isLoading ? (
							<Loading />
						) : (
							<CustomTable
								title={t('bookingPage.canceled')}
								headCells={columns}
								rows={
									data
										?.filter((item: any) => {
											return item.status === -1;
										})
										?.map((item: any) => {
											return {
												id: item.id,
												tour: item?.excursion?.name || item?.event?.name,
												type: t('bookingPage.' + item.type),
												date: item.booking_date,
												name: item.name,
												phone: item.phone,
												status: getBookingStatus(item),
											};
										}) || []
								}
								Actions={Actions}
								deleteSelected={() => {}}
							/>
						)}
					</TabPanel>
				</Tabs>
			</Box>
		</Layout>
	);
}
