import { ModalClose, Sheet } from '@mui/joy';
import JoyTypography from '@mui/joy/Typography';
import { Modal, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type PaymentDetailsModalProps = {
	open: boolean;
	onClose: () => void;
	data: any;
};

export const PaymentDetailsModal = ({ open, onClose, data }: PaymentDetailsModalProps) => {
	const { t } = useTranslation();

	const proccesedData: { [key: string]: string } = {};
	data.forEach((v: any) => (proccesedData[v.key] = v.value));
	console.log('proccesedData', proccesedData);

	return (
		<Modal aria-labelledby="modal-payment-details" aria-describedby="modal-desc" open={open} onClose={onClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<Sheet
				variant="outlined"
				sx={{
					width: '100%',
					overflow: 'auto',
					overflowX: 'hidden',
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
				}}>
				<ModalClose
					variant="outlined"
					sx={{
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
					onClick={onClose}
				/>
				<JoyTypography component="h3" level="h4" textColor="inherit" fontWeight="lg" mb={1}>
					{t('museumAdminPage.paymentDetails')}
				</JoyTypography>

				<Typography>
					{t('settingsPage.recipientName')}: {proccesedData.recipientName || '-'}
				</Typography>
				{proccesedData.paymentMethod === 'card' ? (
					<Typography>
						{t('settingsPage.cardNumber')}: {proccesedData.cardNumber || '-'}
					</Typography>
				) : (
					<>
						<Typography>
							{t('settingsPage.edrpou')}: {proccesedData.edrpou || '-'}
						</Typography>
						<Typography>
							{t('settingsPage.iban')}: {proccesedData.iban || '-'}
						</Typography>
					</>
				)}
			</Sheet>
		</Modal>
	);
};
