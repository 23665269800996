import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '../utils';

export const api = createApi({
	baseQuery: customBaseQuery,
	endpoints: builder => ({
		booking: builder.mutation<any, void>({
			query: () => ({
				url: `/booking`,
				method: 'GET',
			}),
		}),
		updateStatus: builder.mutation<[], any>({
			query: ({ id, status }) => ({
				url: `/booking/status/${id}`,
				method: 'PATCH',
				body: { status },
			}),
		}),
		delete: builder.mutation<any, any>({
			query: id => ({
				url: `/booking/${id}`,
				method: 'DELETE',
			}),
		}),
	}),
});

export const { useBookingMutation, useUpdateStatusMutation, useDeleteMutation } = api;
